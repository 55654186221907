.Header__Main {
    padding-block: 30px;
    padding-inline: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #2F68FA;
    width: 100%;
    flex: 0 1 auto
}

.Header__Title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.Header__Title__Icon {
    height: 36px;
    width: 36px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Header__Title__Text {
    font-size: 36px;
    color: #022873;
    font-weight: 600;
    line-height: 52px;
}

.Header__Search {
    position: relative;
}

.Header__Search__Input {
    border-radius: 6px;
    border: solid 2px #2F68FA;
    padding-block: 15px;
    padding-inline: 10px;
    padding-left: 44px;
    text-transform: uppercase;
    font-size: 16px;
    color: #022873;
    font-weight: 700;
    min-width: 400px;
}

.Header__Search__Input::placeholder {
    color: rgba(2, 40, 115, .6);
}

.Header__Search__Icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 16px;
    background-image: url('../../assets/Icons/Search.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
}