.ProfileModal__Main {
    background-color: #fff;
    padding: 32px;
    border-radius: 24px;
    border: 2px solid #2F68FA;
}

.ProfileModal__Profile {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.ProfileModal__Image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #f3f3f3;
}

.ProfileModal__Text {
    font-size: 30px;
    font-weight: 600;
    color: #022873
}

.ProfileModal__InfoBlocks {
    display: grid;
    gap: 24px;
}

.ProfileModal__InfoBlock {
    display: grid;
    gap: 8px;
}

.ProfileModal__SubHeading {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #022873;
}

.ProfileModal__Body {
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
}

.ProfileModal__File {
    display: flex;
    gap: 5px;
    padding: 5px;
    border-radius: 6px;
    align-items: center;
    text-decoration: none;
}

.ProfileModal__File:hover {
    background-color: #fafafa;
}

.ProfileModal__File__Icon {
    height: 32px;
    width: 32px;
    background-image: url('../../assets/Icons/File.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.BlueButton__Full {
    width: 100%;
    background-color: #2F68FA;
    padding-block: 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin-top: 50px;
    font-family: 'SpaceGrotesk';
}

.GreenButton__Full {
    width: 100%;
    background-color:green;
    padding-block: 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin-top: 50px;
    font-family: 'SpaceGrotesk';
}

.BlueButton__Full:hover {
    background-color: rgb(37, 92, 230);
}

.ProfessionalUpdateText {
    padding-top: -5;
    padding-bottom: -5;
}

.Valid_Text {
    font-size: 14px;
    font-weight: 400;
}