.Professionals__Main {
    overflow-y: scroll;
    flex: 1 1 auto
}

.Professionals__Container {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 20px;
    max-width: 1000px;
}

.Question_Content_Container {
    flex-wrap: 'wrap';
    border: 4px solid #022873;
    border-radius: 30px;
    padding: 20px;
    margin-right: 1%;
    margin-top: 1%;
    max-width: 30%;
    width: 30%
}

.Question_Content_Title {

    font-size: 16px;
    color: #022873;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 2px solid #D1A969;
    margin-bottom: 10px;
}

.Question_Content_Text {
    font-size: 16px;
    color: black;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 2px;
}

.Question_Images {
    width: 170px;
    height: 170px;
    margin: 10px;
    object-fit: cover;
}

.Question_ScrollButtons {
    width: 30px;
    border: 2px solid #D1A969;
    border-radius: 5px;
}

.PDF_style {
    white-space: nowrap;
    overflow: hidden;

}

.PDF_Text {
    text-overflow: ellipsis;
    font-size: 16px;
    color: #022873;
    font-weight: 600;
    border-bottom: 2px solid #D1A969;
}

.Question_Files_Title {
    font-size: 20px;
    margin-top: 10px;
    color: #022873;
    font-weight: 600;
}

.Question_Replys_Title {
    font-size: 20px;
    margin-top: 10px;
    color: #022873;
    font-weight: 600;
    border-top: 2px solid #D1A969;
}

.Replies_Stage_Title {
    font-size: 16px;
    margin-top: 10px;
    color: #022873;
    font-weight: 600;
}