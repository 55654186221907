html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
/* Font imports */

@font-face {
  font-family: "SpaceGrotesk";
  src: url(/src/assets/Fonts/SpaceGrotesk-Light.ttf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SpaceGrotesk";
  src: url(/src/assets/Fonts/SpaceGrotesk-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SpaceGrotesk";
  src: url(/src/assets/Fonts/SpaceGrotesk-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SpaceGrotesk";
  src: url(/src/assets/Fonts/SpaceGrotesk-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SpaceGrotesk";
  src: url(/src/assets/Fonts/SpaceGrotesk-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
}

button {
  outline: none;
}

button:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c2c9d2;
}