.ResultModal__Main {
    background-color: #F2F6F7;
    border-radius: 10px;
    width: 400px;
    height: 400px;
    padding-inline: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    gap: 15px;
}

.ResultModal__Icon {
    height: 100px;
    width: 100px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.ResultModal__Title {
    color: #022873;
    font-size: 30px;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
}

.ResultModal__Body {
    color: #1F1F1F;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
}