.Professionals__Main {
    overflow-y: scroll;
    flex: 1 1 auto
}

.Professionals__Container {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 20px;
    max-width: 1000px;
}

.BlueButton__Full {
    width: 100%;
    background-color: #2F68FA;
    padding-block: 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin-top: 50px;
    font-family: 'SpaceGrotesk';
}

.Email_Input1 {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border-color: #2F68FA;
    border-width: 2px;
    padding: 10px;
    font-size: 20px;

}

.Email_Input2 {
    height: 100px;
    border-radius: 10px;
    border-color: #2F68FA;
    border-width: 2px;
    padding: 10px;
    font-size: 20px;
}