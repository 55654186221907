.SignoutModal__Main {
    background-color: #F2F6F7;
    border-radius: 10px;
    width: 400px;
    padding-inline: 30px;
    padding-block: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    gap: 15px;
}

.SignoutModal__Title {
    color: #022873;
    font-size: 30px;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
}

.Button__Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
}

.RedButton__Double {
    width: 100%;
    background-color: #F86454;
    padding-block: 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    font-family: 'SpaceGrotesk';
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.RedButton__Double:hover {
    background-color: #e05545;
}

.BlueButton__Double {
    width: 100%;
    background-color: transparent;
    padding-block: 14px;
    border-radius: 6px;
    color: #2F68FA;
    font-size: 14px;
    font-weight: 700;
    border: none;
    border: 2px solid #2F68FA;
    font-family: 'SpaceGrotesk';
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.BlueButton__Double:hover {
    background-color: rgba(0,0,0,0.04);
}