.Home__Main {
    display: grid;
    grid-template-columns: 1fr 4fr;
    height: 100vh;
}

.Home__Content {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    flex-flow: column;
}

.Name_Title {
    margin-top: 20px;
    margin-Left: 20px;
    font-size: 24px;
    color: #022873;
    font-weight: 600;
    border-bottom: 2px solid #D1A969;
}

.Title__Container {
    padding: 15px;
    background-color:lightgray;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Details_Title {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    font-size: 18px;
    color: #022873;
    font-weight: 600;
}

.Questions {
    margin-top: 20px;
    margin-left: 50px;
    font-size: 24px;
    color: #022873;
    font-weight: 600;
    border-bottom: 2px solid #D1A969;
    width: 140px
}

.Profile__Image {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #f3f3f3;
}

.User_Data {
    font-size: 18px;
    color: #D1A969;
    font-weight: 600;
}

.loader {
    margin-top: 50px;
    align-self: center;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #022873; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }