@media only screen and (min-width: 1000px) {

    .Authentication__Main {
        background-color: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
    }

    .Authentication__Container {
        background-color: #fff;
        padding: 32px;
        border-radius: 10px;
        min-width: 450px;
        display: grid;
        gap: 15px;
    }

    .TFA__Logo {
        background-image: url('../../assets/Logos/TFALogo.png');
        background-position: 0%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 60%;
        padding-bottom: 10%;
    }

    .Auth__Title {
        color: #022873;
        font-size: 18px;
        font-weight: 600;
    }

    input {
        border-radius: 6px;
        border: solid 2px #2F68FA;
        padding-block: 15px;
        padding-inline: 10px;
        text-transform: uppercase;
        font-size: 16px;
        color: #022873;
        font-weight: 700;
        min-width: 400px;
    }

    .Input__Error {
        border: solid 2px #e05545;
    }

    .Input__Error::placeholder {
        color: #be5347a7;
    }

    .BlueButton__Full {
        width: 100%;
        background-color: #2F68FA;
        padding-block: 16px;
        border-radius: 6px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none;
        margin-top: 20px;
        text-transform: uppercase;
        font-family: 'SpaceGrotesk';
        text-align: center;
        text-decoration: none;
    }

    .MobileMessage__Main {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {

    .MobileMessage__Main {
        display: flex;
        height: 100vh;
        width: 100vw;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #022873;
        font-weight: 700;
        flex-wrap: wrap;
        align-content: center;
        text-align: center;
        padding: 5%;
    }

    .Authentication__Container {
        display: none;
    }

    .TFA__Logo {
        background-image: url('../../assets/Logos/TFALogo.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 400px;
        padding-bottom: 10%;
        margin-bottom: 20px;
    }
}