.PanelOption__Main {
    padding-block: 30px;
    padding-inline: 15px;
    border-top: solid 1px #2F68FA;
}

.PanelOption__Title {
    font-size: 14px;
    color: #D1A969;
    font-weight: 700;
    margin-bottom: 8px;
}

.PanelOption__Button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    padding-block: 5px;
    text-decoration: none;
    font-weight: 500;
}

.PanelOption__Button:hover {
    background-color: #fafafa;
}

.PanelOption__Button__Active {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    padding-block: 5px;
    padding-inline: 10px;
    text-decoration: none;
    font-weight: 700;
    background-color: #02287310;
}

.PanelOption__Button__Icon {
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.PanelOption__Button__Text {
    font-size: 16px;
    color: #022873;
    text-decoration: none;
}