.ApplicationsPage__Main {
    display: grid;
    grid-template-columns: 1fr 4fr;
    height: 100vh;
}

.ApplicationsPage__Content {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    flex-flow: column;
}

.noProfessionals {
    width: 100%;
    text-align: center;
    background-color: #fafafa;
    padding-block: 25px;
    border-radius: 5px;
    color: rgba(31, 31, 31, 0.8);
}