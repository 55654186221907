.SidePanel__Main {
    border-right: solid 1px #2F68FA;
    height: 100%;
    min-width: 300px;
}

.SidePanel__LogoContainer {
    padding-block: 30px;
    padding-inline: 15px;
}

.SidePanel__Logo {
    background-image: url('../../assets/Icons/Logo.png');
    background-position: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 52px;
    width: 100%;
}