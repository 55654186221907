.Report__Main {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 6px;
}

.Report__Main:hover {
    background-color: #fafafa;
}

.Reports__Image {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #f3f3f3;
}

.Reports__Name {
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 600;
}

.Reports__ViewButton {
    width: 42px;
    height: 42px;
    background-image: url('../../assets/Icons/Applications.png');
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    margin-left: auto;
}


.Reports__ViewButton:hover {
    background-color: rgba(47, 104, 250, .2);
}