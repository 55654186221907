.Applications__Main {
    padding-block: 30px;
    padding-inline: 15px;
}

.Applications__Tabs {
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: 20px;
}

.Applications__Tab {
    font-size: 24px;
    color: #022873;
    font-weight: 600;
    padding-bottom: 8px;
    cursor: pointer;
}

.Applications__Tab:hover {
    font-size: 24px;
    color: rgba(2, 40, 115, .6);
    font-weight: 600;
    padding-bottom: 8px;
    cursor: pointer;
}

.Applications__Tab__Selected {
    font-size: 24px;
    color: #022873;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 2px solid #D1A969;
    cursor: pointer;
}

.Applications__Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 20px;
    max-width: 1000px;
}

.noProfessionals {
    grid-column: 1/3;
}
