.ReportModal__Main {
    background-color: #fff;
    padding: 32px;
    border-radius: 24px;
    border: 2px solid #2F68FA;
    max-height: 80vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ReportModal__Main::-webkit-scrollbar {
    display: none;
}

.ReportModal__Profile {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.ReportModal__Image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #f3f3f3;
}

.ReportModal__Text {
    font-size: 30px;
    font-weight: 600;
    color: #022873
}

.ReportModal__InfoBlocks {
    display: grid;
    gap: 24px;
    margin-bottom: 20px;
}

.ReportModal__InfoBlock {
    display: grid;
    gap: 8px;
}

.ReportModal__SubHeading {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #022873;
}

.ReportModal__Body {
    font-size: 14px;
    font-weight: 400;
    color: #1F1F1F;
}

.ReportModal__Question {
    padding-block: 11px;
    padding-inline: 13px;
    background-color: #fff;
    border: #2F68FA 1px solid;
    border-radius: 10px;
}

.Question {
    font-size: 18px;
    color: #2F68FA;
    font-weight: 400;
    margin-right: 10px;
}

.Question__Container {
    display: flex;
    margin-bottom: 6px;
}

.Question__Price {
    font-size: 18px;
    color: #022873;
    font-weight: 700;
}

.Question__Content {
    font-size: 22px;
    color: #022873;
    font-weight: 600;
    margin-bottom: 10px;
}

.Question__Status__Posted,
.Question__Status__Working,
.Question__Status__Complete,
.Question__Status__PickUp,
.Question__Status__Answered,
.Question__Status__Cancelled {
    padding-inline: 10px;
    padding-block: 3px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    display: inline;
}

.Question__Status__Posted {
    color: #E8C244;
    background-color: rgba(232, 194, 68, 0.2);
}

.Question__Status__Complete {
    color: #5BDB5B;
    background-color: rgba(91, 219, 91, 0.2);
}

.Question__Status__Cancelled {
    color: #F86454;
    background-color: rgba(248, 100, 84, 0.2);
}

.Response__Content {
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 400;
}

.BlueButton__Full {
    width: 100%;
    background-color: #2F68FA;
    padding-block: 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin-top: 50px;
    font-family: 'SpaceGrotesk';
}

.Professional__Profile {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fafafa;
    margin-top: 8px;
}

.ReportProfessional__Image {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #f3f3f3;
    margin-right: 10px;
}

.Profile__Name {
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 600;
}

.BlueButton__Full:hover {
    background-color: rgb(37, 92, 230);
}

.ReportModal__ProfessionalContent {
    display: grid;
    gap: 20px
}

.Response__Content {
    margin-bottom: 10px;
}

.Response__Container {
    margin-top: 5px;
}

.ReportModal__ReplyTitle {
    color: #022873;
    font-weight: 600;
    margin-bottom: 2px;
}

.ReportModal__Response {
    margin-top: 10px;
}