.Reports__Main {
    padding-block: 30px;
    padding-inline: 15px;
}

.Reports__Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 20px;
    max-width: 1000px;
}

.noReports {
    grid-column: 1/3;
}
