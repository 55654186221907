.Professionals__Main {
    overflow-y: scroll;
    flex: 1 1 auto;
}

.Professionals__Container1 {

}

.Professionals_Details {
    border-style: solid;
    border-width: 0px 0px 1px 1px;
    border-color: #2F68FA;
    padding: 0px 5px 0px 5px;
    width: 300px;
    max-width: 300px;


}